import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';  // Import moment
import axios from "axios";
import Table from "./Table";

const CheckLogs = () => {
  const [date, setDate] = useState(new Date());
  const [percent, setPercent] = useState('');
  const [data, setData] = useState([]);

  // Use moment to format date
  const formattedDate = moment(date).format('YYYY-MM-DD');
console.log("formatdare",formattedDate)
  useEffect(() => {
    const intervalId = setInterval(() => {
      axios.get('https://report.gamestation.mobi/api/ratio')
        .then(response => {
          setPercent(response.data.ratio[0].selectRatio);
        })
        .catch(error => {
          console.error('There was an error fetching the data:', error);
        });
    }, 2000); 

    return () => clearInterval(intervalId);
  }, []); 

  const fetchData = async () => {
    if (date) {
      try {
        const response = await axios.post("/api/getlatest", {
          mis_date: formattedDate,  // Use moment formatted date
        });

        setData(response.data);  // Update state with response data
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 3000);
    return () => clearInterval(intervalId);
  }, [date]);  // Re-fetch when date changes

  return (
    <>
      <div className="container px-5 flex mx-auto justify-center mt-10">
        <div className="block max-w-sm p-6 bg-gray-100 border shadow-lg shadow-purple-500 border-gray-200 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <div className="flex justify-center border border-purple-500">
            <DatePicker
              isClearable
              selected={date}
              onChange={(date) => setDate(date)}
              wrapperClassName="datePicker"
            />
          </div>
          <div className="m-2 p-2">
            {data.length <= 0 ? (
              <h1 className="text-3xl font-bold font-mono bg-green-500 text-white p-2 text-center rounded">
                Nothing To Show
              </h1>
            ) : (
              <Table data={data} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckLogs;
